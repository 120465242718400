import React, { Component } from 'react';
import './App.css';

import { Switch, Route } from 'react-router-dom';

import Home from './components/pages/Home';
import Disclaimer from './components/pages/Disclaimer';
import Privacy from './components/pages/Privacy';

class App extends Component {
  render() {
    return (
        <Switch>
          <Route path="/" exact component={Home} />
          <Route path="/disclaimer" exact component={Disclaimer} />
          <Route path="/privacy" exact component={Privacy} />
        </Switch>
    );
  }
}

export default App;
