import React from 'react';
import {  MDBRow, MDBCol, MDBCard, MDBCardBody, MDBContainer } from "mdbreact";

import Footer from "../organisms/Footer";
const Privacy = () => (
    <div id={'disclaimer'}>
        <MDBContainer className={'content'}>
            <MDBRow className="align-items-center mt-5">
              
              <a href={'/'} style={{color: '#f2930d', marginTop: '-50px'}}>&larr; Keer terug</a>
            </MDBRow>
            <MDBRow className="align-items-center mt-5">
                
                <h4 className="grey-text" style={{ margin: "0px" }}>
                <strong>Privacy</strong>
                </h4>
            </MDBRow>
            <hr className="mb-5" />
      <MDBCard className="my-5 px-5 pb-5">
        <MDBCardBody>
          <MDBRow>
            <MDBCol lg="12">
            <p>
            <strong>Demarbit verwerkt personengegevens overeenkomstig met onderstaande privacyverklaring. Voor meer informatie kan u steeds contact met ons opnemen via het e-mail adres info@demarbit.be</strong>
            </p>
              <h3 className="font-weight-bold mb-3 p-0">
                <strong>Personengegevens</strong>
              </h3>
              <p>
              Demarbit verzamelt en verwerkt volgende gegevens:<br />
              <ul>
                <li><strong>Algemeen gebruik website</strong>: Bij het algemeen gebruik gebruik van de website worden er geen personengegevens bewaard of verwerkt</li>
                <li><strong>Contactformulier</strong>: Wanneer u het contactformulier onderaan de website invult, worden uw naam en email adres gebruikt om contact met u op te nemen. Deze gegevens zijn enkel beschikbaar op onze mailserver en worden verder niet gebruikt voor andere doeleinden. Een uitzondering op deze regel is dat wanneer u de optie 'Inschrijven op nieuwsbrief' aanvinkt wij uw emailadres zullen opslaan in een interne databank om u later onze nieuwsbrieven te verzenden. U kan zich steeds uitschrijven. </li>
              </ul>
            </p>
              <h3 className="font-weight-bold mb-3 p-0">
                <strong>Rechtmatigheid van de verwerking</strong>
              </h3>
              <p>
              Personengegevens worden verwerkt op basis van artikel 6.1 van de Algemene Verordering Gegevensbescherming:
              <ul>
                <li>(a) Toestemming</li>
                <li>(b) Noodzakelijk voor de uitvoering van een overeenkomst</li>
              </ul>
              De gebruiker heeft steeds het recht om de gegeven toestemming opnieuw in te trekken.
              </p>
              <h3 className="font-weight-bold mb-3 p-0">
                <strong>Overmaken aan derden</strong>
              </h3>
              <p>De persoonlijke gegevens (naam en e-mail adres) die u opgeeft in het contact formulier zullen enkel gedeeld worden met de opdrachtgever van Demarbit. Wij verbinden ons ertoe dat de ontvangers de nodige technische en organisatorische maatregelen treffen om deze gegevens verder te beschermen. Demarbit verkoopt geen personengegevens aan derden voor commerciele doeleinden.</p>
              <h3 className="font-weight-bold mb-3 p-0">
                <strong>Beveiliging van gegevens</strong>
              </h3>
              <p>
              De mails die persoonlijke gegevens van onze gebruikers bevatten worden beheerd door de mailservers van het bedrijf <a href='https://www.zoho.com/mail/' target='_blank'  rel="noopener noreferrer">Zoho</a>. Demarbit heeft passende technische en organisatorische maatregelen genomen om personengegevens te beschermen, zowel op de gehoste mailserver als in alle communicatie van en naar deze server.
              </p>
            </MDBCol>
          </MDBRow>
        </MDBCardBody>
        </MDBCard>  
        </MDBContainer>
        <Footer/>
    </div>
);

export default Privacy;