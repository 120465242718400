import React from 'react';
import {
    MDBContainer,
    MDBRow,
    MDBCol,
    MDBMask,
    MDBIcon,
    MDBView,
  } from 'mdbreact';
import './Home.css';
import Footer from '../organisms/Footer';
import { MODELS, LOGO, LOGOTRANS, CONTACT, ZAAK } from '../../static/images';
import Lightbox from "react-image-lightbox";

class Home extends React.Component {
    state = {
      contactName: '',
      contactMail: '',
      contactMessage: '',
      error: false,
      success: false,
      photoIndex: 0,
      salonIndex: 0,
      isSalonOpen: false,
      isOpen: false
    }

    renderImage(colwidth, index) {
      return (
        
        <MDBCol md={colwidth} key={index}>
          <figure className="figure-thumb"><img src={MODELS[index]} alt='' className="img-fluid img-thumb" onClick={() => this.setState({ photoIndex: index, isOpen: true })} /></figure>
        </MDBCol>
      );
    }

    renderSalonImage(colwidth, index) {
      return (
        
        <MDBCol md={colwidth} key={index}>
          <figure className="figure-thumb"><img src={ZAAK[index]} alt='' className="img-fluid img-thumb" onClick={() => this.setState({ salonIndex: index, isSalonOpen: true })} /></figure>
        </MDBCol>
      );
    }

    render() {
      const { photoIndex, isOpen, salonIndex, isSalonOpen } = this.state;
        return (
            <div id="landing">

            <section id="home" className={'orange-border'}>
            <MDBView src={MODELS[1]} className={'bordered'}>
              <MDBMask
                className="d-flex justify-content-center align-items-center"
                overlay="gradient"
              >
                <MDBContainer className="h-100 d-flex justify-content-center align-items-center">
                  <MDBRow className="flex-center pt-5 mt-3">

                    <MDBCol md="12" className="text-center text-md-left mb-8">
                        <div className="white-text">
                            <img src={LOGOTRANS} alt='' className={'centered'}/>
                            <hr className="hr-light wow fadeInLeft" data-wow-delay="0.3s" />
                            <h6 className="wow fadeInLeft" data-wow-delay="0.3s" style={{textAlign: 'center', fontSize: '1.5rem'}} >
                            N&eacute;t dat haartje beter!
                            </h6>
                            <br/>
                        </div>
                    </MDBCol>

             
                  </MDBRow>
                </MDBContainer>
              </MDBMask>
            </MDBView>
          </section>
          <MDBContainer>
          <section id="overview" style={{marginTop: '50px'}}>
          <MDBRow>
            <MDBCol lg="3">
              <MDBView className="rounded z-depth-2 mb-lg-0 mb-3 company-primary">
                <img
                  className="img-fluid centered"
                  src={LOGO}
                  alt=""
                />
              </MDBView>
            </MDBCol>
            <MDBCol lg="9">
            <h3 className="font-weight-bold mb-3 p-0">
                <strong>Kap &amp; Sfeer</strong>
              </h3>
              
              <p>
                Welkom op de website van kapsalon Kap &amp; Sfeer te Wilrijk. De locatie voor vrouwen, mannen en kinderen voor een nieuw kapsel!
              </p>
              <p>
                U kan bij ons zonder afspraak terecht voor knippen, brushen en kleuren. Wij streven ernaar om altijd op de hoogte te zijn van de laatste technieken en trends in de kapperswereld.
              </p>
              <p>
                Ons doel is om samen met jou het perfecte kapsel te vinden. Wil je meer informatie over de mogelijkheden of prijzen, bel ons op of kom even langs in onze zaak. Wij staan u altijd met plezier te woord en maken graag een prijsofferte op!
              </p>         
              <p>
                Om iedereen zo goed mogelijk van dienst te kunnen zijn tijdens de feestdagen, zullen onze openingsuren in die periode wijzigen:
                <div style={{ fontSize: '0.9rem'}}>
                  <br />
                  <span>Maandag 23/12: 9u - 18u30</span>
                  <br />
                  <span>Dinsdag 24/12: 9u - 16u</span>
                  <br />
                  <span>Woensdag 25/12 &amp; donderdag 26/12: gesloten</span>
                  <br />
                  <span>Maandag 30/12: 9u - 18u30</span>
                  <br />
                  <span>Dinsdag 31/12: 9u - 16u</span>
                  <br />
                  <span>Woensdag 01/01 &amp; donderdag 02/01: gesloten</span>
                </div>
              </p>
            </MDBCol>
          </MDBRow>
          <hr className="my-5" />
          </section>
          <section id="salon">
          <h3 className="font-weight-bold mb-3 p-0">
            <strong>Onze zaak</strong>
          </h3>
          <br />
          <div className="mdb-lightbox no-margin">
            <MDBRow>
                { this.renderSalonImage(3, 0) }
                { this.renderSalonImage(3, 1) }
                { this.renderSalonImage(3, 2) }
                { this.renderSalonImage(3, 3) }
                { this.renderSalonImage(3, 4) }
                { this.renderSalonImage(3, 5) }
            </MDBRow>
            </div>
            {isSalonOpen && (
            <Lightbox
                mainSrc={ZAAK[salonIndex]}
                nextSrc={ZAAK[(salonIndex + 1) % ZAAK.length]}
                prevSrc={ZAAK[(salonIndex + ZAAK.length - 1) % ZAAK.length]}
                imageTitle={salonIndex + 1 + "/" + ZAAK.length}
                onCloseRequest={() => this.setState({ isSalonOpen: false })}
                onMovePrevRequest={() =>
                this.setState({
                  salonIndex: (salonIndex + ZAAK.length - 1) % ZAAK.length
                })
                }
                onMoveNextRequest={() =>
                this.setState({
                  salonIndex: (salonIndex + 1) % ZAAK.length
                })
                }
            />
            )}
            <hr className="my-5" />
          </section>
          <section id="recent-news">
          <h3 className="font-weight-bold mb-3 p-0">
                  <strong>Inspiratie</strong>
                </h3>
                <br/>
          <div className="mdb-lightbox no-margin">
            <MDBRow>
                { this.renderImage(3, 0) }
                { this.renderImage(3, 1) }
                { this.renderImage(2, 2) }
                { this.renderImage(3, 3) }
                { this.renderImage(6, 4) }
                { this.renderImage(2, 5) }
                { this.renderImage(3, 6) }
                { this.renderImage(3, 7) }
                { this.renderImage(2, 10) }
                { this.renderImage(3, 8) }
                { this.renderImage(3, 9) }
            </MDBRow>
            </div>
            {isOpen && (
            <Lightbox
                mainSrc={MODELS[photoIndex]}
                nextSrc={MODELS[(photoIndex + 1) % MODELS.length]}
                prevSrc={MODELS[(photoIndex + MODELS.length - 1) % MODELS.length]}
                imageTitle={photoIndex + 1 + "/" + MODELS.length}
                onCloseRequest={() => this.setState({ isOpen: false })}
                onMovePrevRequest={() =>
                this.setState({
                    photoIndex: (photoIndex + MODELS.length - 1) % MODELS.length
                })
                }
                onMoveNextRequest={() =>
                this.setState({
                    photoIndex: (photoIndex + 1) % MODELS.length
                })
                }
            />
            )}
          <hr className="my-5" />
          </section>
          <section id="contact">
            <h2 className="text-center my-5 h1">Contact</h2>
            <MDBRow>
              <MDBCol lg="8">
              <img
                  className="img-fluid"
                  src={CONTACT}
                  alt=""
                />
              </MDBCol>
              <MDBCol lg="4">
                  <ul className="text-center list-unstyled">
                    <li style={{marginBottom: '10px'}}>
                        <MDBIcon icon="map-marker-alt" size="2x" />
                      <p>
                        Prins Boudewijnlaan 88, 2610 Wilrijk
                      </p>
                    </li>
                    <li>
                        <MDBIcon icon="phone" size="2x" />
                      <p>
                      03/281.45.60
                      </p>
                    </li>
                    <li>
                      <MDBIcon icon="clock" size="2x" />
                      <table style={{margin: '0 auto'}}>
                        <tbody>
                          <tr><td style={{textAlign: 'right'}}>Maandag</td><td style={{textAlign: 'left', paddingLeft: '25px'}}><i>gesloten</i></td></tr>
                          <tr><td style={{textAlign: 'right'}}>Dinsdag</td><td style={{textAlign: 'left', paddingLeft: '25px'}}><i>gesloten</i></td></tr>
                          <tr><td style={{textAlign: 'right'}}>Woensdag</td><td style={{textAlign: 'left', paddingLeft: '25px'}}><i>09.00 - 18.30</i></td></tr>
                          <tr><td style={{textAlign: 'right'}}>Donderdag</td><td style={{textAlign: 'left', paddingLeft: '25px'}}><i>09.00 - 18.30</i></td></tr>
                          <tr><td style={{textAlign: 'right'}}>Vrijdag</td><td style={{textAlign: 'left', paddingLeft: '25px'}}><i>09.00 - 18.30</i></td></tr>
                          <tr><td style={{textAlign: 'right'}}>Zaterdag</td><td style={{textAlign: 'left', paddingLeft: '25px'}}><i>08.30 - 18.00</i></td></tr>
                          <tr><td style={{textAlign: 'right'}}>Zondag</td><td style={{textAlign: 'left', paddingLeft: '25px'}}><i>Gesloten</i></td></tr>
                        </tbody>
                      </table>
                    </li>
                  </ul>
              </MDBCol>
            </MDBRow>
          </section>

        </MDBContainer>
        <Footer />
      </div>
        );
    }
}

export default Home;