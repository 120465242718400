import logoimage from './logo.png';
import logotrans from './logo-transparent.png'
import contactImage from './contact.png';

import inspiration1 from './inspiration1.JPEG';
import inspiration2 from './inspiration2.JPEG';
import inspiration3 from './inspiration5.jpg';
import inspiration4 from './inspiration3.jpg';
import inspiration5 from './inspiration6.JPEG';
import inspiration6 from './inspiration4.jpg';
import inspiration7 from './inspiration7.jpg';
import inspiration8 from './inspiration10.jpg';
import inspiration9 from './inspiration8.jpg';
import inspiration10 from './inspiration9.JPEG';
import inspiration11 from './inspiration11.jpg';

import salon1 from './salon1.jpeg';
import salon2 from './salon2.jpeg';
import salon3 from './salon3.jpeg';
import salon4 from './salon4.jpeg';

import zaak1 from './zaak1.jpg';
import zaak2 from './zaak2.jpg';
import zaak4 from './zaak4.jpg';
import zaak5 from './zaak5.jpg';
import zaak6 from './zaak6.jpg';
import zaak9 from './zaak9.jpg';

export const MODELS = [ inspiration3,inspiration1, inspiration4, inspiration5, inspiration8, inspiration10, inspiration11, inspiration6, inspiration7, inspiration9, inspiration2 ]
export const SALON = [ salon2, salon1, salon3, salon4 ];
export const ZAAK = [ zaak9, zaak1, zaak2, zaak4, zaak5, zaak6 ]
export const LOGO = logoimage;
export const LOGOTRANS = logotrans;
export const CONTACT = contactImage;