import React from 'react';
import {  MDBRow, MDBCol, MDBCard, MDBCardBody, MDBContainer } from "mdbreact";

import Footer from "../organisms/Footer";
const Disclaimer = () => (
    <div id={'disclaimer'}>
        <MDBContainer className={'content'}>
            <MDBRow className="align-items-center mt-5">
              
              <a href={'/'} style={{color: '#f2930d', marginTop: '-50px'}}>&larr; Keer terug</a>
            </MDBRow>
            <MDBRow className="align-items-center mt-5">
                <h4 className="grey-text" style={{ margin: "0px" }}>
                <strong>Disclaimer</strong>
                </h4>
            </MDBRow>
            <hr className="mb-5" />
      <MDBCard className="my-5 px-5 pb-5">
        <MDBCardBody>
          <MDBRow>
            <MDBCol lg="12">
            <p>
            Deze website wordt uitgebaat Demarbit (info@demarbit.be)
            </p>
              <h3 className="font-weight-bold mb-3 p-0">
                <strong>Aansprakelijkheid</strong>
              </h3>
              <p>
              De informatie op deze website is louter informatief en is van algemene aard. De teksten of afbeeldingen op deze website zijn niet aangepast aan persoonlijke of specifieke omstandigheden en kunnen bijgevolg niet als persoonlijk, professioneel of juridisch advies aan de gebruiker beschouwd worden.
            </p>
            <p>
Wij leveren steeds grote inspanningen om de informatie die op deze website ter beschikking gesteld wordt, correct en up-to-date te houden. Ondanks deze inspanningen kunnen onregelmatigheden alsnog voorvallen.
</p>
            <p>
Demarbit kan niet aansprakelijk gesteld worden voor rechtstreekse of onrechtstreekse schade die ontstaat uit het gebruik van informatie op deze website. Indien u vaststelt dat er onjuiste informatie op de website staat kan u steeds contact opnemen met de beheerder (info@demarbit.be).
</p>
            <p>
De inhoud van deze website - teksten, afbeeldingen, links - kunnen ten allen tijde en zonder voorafgaande waarschuwing aangepast worden. Demarbit biedt geen garanties voor een goede werking van de website en kan bijgevolg ook niet aansprakelijk gesteld worden voor het slecht functioneren of niet beschikbaar zijn van deze website.
</p>
            <p>
Deze website kan mogelijk hyperlinks bevatten naar websites van derden of daar onrechtstreeks naar verwijzen. Het opnemen van deze hyperlinks op onze website impliceert op geen enkele wijze een impliciete goedkeuring van de inhoud ervan. Demarbit verklaart dan ook expliciet dat zij geen zeggenschap hebben over de inhoud of beschikbaarheid van deze externe websites en kan bijgevolg ook niet aansprakelijk gesteld worden voor geleden schade bij het gebruik ervan.
              </p>
              <h3 className="font-weight-bold mb-3 p-0">
                <strong>Intellectuele eigendom</strong>
              </h3>
                <p>Alle teksten en afbeeldingen op deze website behoren toe aan Demarbit of rechthoudende derden. Deze informatie hergebruiken is enkel toegestaan mits toestemming van de eigenaar.</p>
              <h3 className="font-weight-bold mb-3 p-0">
                <strong>Toepasselijk recht en bevoegde rechtbanken</strong>
              </h3>
              <p>Het Belgisch recht is van toepassing op deze website. In het geval van een geschil zijn enkel de rechtbanken uit het arrondissement van Demarbit bevoegd.</p>
            </MDBCol>
          </MDBRow>
        </MDBCardBody>
        </MDBCard>  
        </MDBContainer>
        <Footer/>
    </div>
);
/*
Deze website is eigendom van Demarbit
info@demarbit.be
Aansprakelijkheid

De informatie op deze website is louter informatief en is van algemene aard. De teksten of afbeeldingen op deze website zijn niet aangepast aan persoonlijke of specifieke omstandigheden en kunnen bijgevolg niet als persoonlijk, professioneel of juridisch advies aan de gebruiker beschouwd worden.

Wij leveren steeds grote inspanningen om de informatie die op deze website ter beschikking gesteld wordt, correct en up-to-date te houden. Ondanks deze inspanningen kunnen onregelmatigheden alsnog voorvallen.

Demarbit kan niet aansprakelijk gesteld worden voor rechtstreekse of onrechtstreekse schade die ontstaat uit het gebruik van informatie op deze website. Indien u vaststelt dat er onjuiste informatie op de website staat kan u steeds contact opnemen met de beheerder (info@demarbit.be).

De inhoud van deze website - teksten, afbeeldingen, links - kunnen ten allen tijde en zonder voorafgaande waarschuwing aangepast worden. Demarbit biedt geen garanties voor een goede werking van de website en kan bijgevolg ook niet aansprakelijk gesteld worden voor het slecht functioneren of niet beschikbaar zijn van deze website.

Deze website kan mogelijk hyperlinks bevatten naar websites van derden of daar onrechtstreeks naar verwijzen. Het opnemen van deze hyperlinks op onze website impliceert op geen enkele wijze een impliciete goedkeuring van de inhoud ervan. Demarbit verklaart dan ook expliciet dat zij geen zeggenschap hebben over de inhoud of beschikbaarheid van deze externe websites en kan bijgevolg ook niet aansprakelijk gesteld worden voor geleden schade bij het gebruik ervan.
Intellectuele eigendom

Alle teksten en afbeeldingen op deze website behoren toe aan Demarbit of rechthoudende derden. Deze informatie hergebruiken is enkel toegestaan mits toestemming van de eigenaar.
Toepasselijk recht en bevoegde rechtbanken

Het Belgisch recht is van toepassing op deze website. In het geval van een geschil zijn enkel de rechtbanken uit het arrondissement van Demarbit bevoegd.
*/
export default Disclaimer;