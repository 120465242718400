import React from 'react';

import { MDBFooter, MDBContainer, MDBRow, MDBCol } from 'mdbreact';
import './color.css';

const Footer = () => (
    <MDBFooter color={'company-primary'} className="pt-3 mt-5 text-center text-md-right">
    <MDBContainer>
      <MDBRow>
          <MDBCol md="4">
          </MDBCol>
          <MDBCol md="4">
            <ul className="list-unstyled" style={{position: 'center'}}>
                <li className="list-inline-item"><a href="/disclaimer">Disclaimer</a></li>
                <li className="list-inline-item">&#9679; </li>
                <li className="list-inline-item"><a href="/privacy">Privacy</a></li>
                <li className="list-inline-item">&#9679; </li>
                <li className="list-inline-item"><a href="https://www.facebook.com/kapensfeer/">Facebook</a></li>
            </ul>
          </MDBCol>
          <MDBCol md="4"></MDBCol>
      </MDBRow>
      </MDBContainer>
    </MDBFooter>
);

export default Footer;